import { Store } from './Store';

export enum PixKeyType {
  CPF = 'CPF',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  RANDOM = 'RANDOM',
}

export enum CashbackStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  CANCELED = 'CANCELED',
}

export const CashbackStatusMap = {
  [CashbackStatus.PENDING]: 'cashback-item-pending-text',
  [CashbackStatus.SUCCESS]: 'cashback-item-success-text',
  [CashbackStatus.CANCELED]: 'cashback-item-canceled-text',
};

export const CashbackDescriptionMap = {
  [CashbackStatus.PENDING]: 'cashback-item-pending-description',
  [CashbackStatus.SUCCESS]: 'cashback-item-success-description',
  [CashbackStatus.CANCELED]: 'cashback-item-canceled-description',
};

export type PreviousStatus = {
  status: CashbackStatus;
  registeredAt: string;
};

export type CashbackUserInfo = {
  id: string;
  cashbackValue: number;
  cashbackPercentage: number;
  totalValue: number;
  registeredAt: string;
  status: CashbackStatus;
  store: Store;
  previousStatus: PreviousStatus[];
};

export type CashbackTip = {
  question: string;
  answer: string;
};
