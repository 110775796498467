export const UPDATE_BANK_ACCOUNT_MUTATION = `
mutation UpdateBankAccountDetails(
    $bankAccountDetails: bankAccountDetailsInput!
) {
    me {
        updateBankAccountDetails(
            bankAccountDetails: $bankAccountDetails
        ) {
            bankAccountDetails {
                cpf,
                fullName,
                pix {
                    type,
                    key
                }
            }
        }
    }
}
`;
