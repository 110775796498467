import { CircleInfo, Envelope } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { Icon } from '@pelando/components';
import {
  Container,
  IconTitle,
  ModalButton,
  Subtitle,
  Tip,
  Title,
} from './styles';

export type ConfirmEmailModalProps = {
  onConfirm: () => void;
  onBackToHome: () => void;
  emailHasSent?: boolean;
  customContent?: string;
  hideCancel?: boolean;
  isCashbackPage?: boolean;
};

export function ConfirmEmailModal({
  onConfirm,
  onBackToHome,
  emailHasSent,
  hideCancel,
  isCashbackPage,
}: ConfirmEmailModalProps) {
  const { t } = useTranslation('privateMessage');

  const titleText = emailHasSent
    ? t('confirm-email-modal-title-has-sent')
    : t('confirm-email-modal-title');

  const subtitleText = emailHasSent
    ? t('confirm-email-modal-subtitle-has-sent')
    : t('confirm-email-modal-subtitle');

  const subtitleTextCashback = t('confirm-email-modal-subtitle-cashback');

  const tipCashback = t('confirm-email-modal-tip-cashback');

  return (
    <Container>
      <IconTitle icon={Envelope} />
      <Title>{titleText}</Title>
      <Subtitle>
        {isCashbackPage ? subtitleTextCashback : subtitleText}
      </Subtitle>
      {isCashbackPage ? (
        <Tip>
          <Icon icon={CircleInfo} />
          <span>{tipCashback}</span>
        </Tip>
      ) : null}
      {!emailHasSent && (
        <ModalButton onClick={onConfirm}>
          {t('confirm-email-modal-button-confirm')}
        </ModalButton>
      )}
      {!hideCancel && !isCashbackPage ? (
        <ModalButton onClick={onBackToHome} light={!emailHasSent}>
          {t('confirm-email-modal-button-on-back')}
        </ModalButton>
      ) : undefined}
    </Container>
  );
}
