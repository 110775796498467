export const CASHBACKS_FRAGMENT = `
fragment CashbackUserInfoFragment on CashbackUserInfo {
    id
    status
    cashbackValue
    totalValue
    cashbackPercentage
    registeredAt
    store {
          id
          name
           image {
            id
            url(width:60 height:60)
        }
        }
    previousStatus  {
        status
        registeredAt
    }  
}
`;
