import {
  CashbackStatus,
  type CashbackUserInfo,
} from '@/domain/entities/Cashback';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { Clock, CircleCheck, Ban } from '@pelando/fontawesome/icons';
import { CashbackItem } from '../CashbackItem';
import { useCashbackDetailsModal } from '../CashbackDetailsModal';

import { CashbackItemButton, Container, List, Title } from './style';

export type CashbackHistoryProps = {
  items: CashbackUserInfo[];
};

const CashBackIcon = {
  [CashbackStatus.PENDING]: Clock,
  [CashbackStatus.SUCCESS]: CircleCheck,
  [CashbackStatus.CANCELED]: Ban,
};

export const CashbackHistory = ({ items = [] }: CashbackHistoryProps) => {
  const { showDetailsModal } = useCashbackDetailsModal();
  const { t } = useTranslation('cashbackBalance');

  return (
    <Container>
      <Title>{t('cashback-history-title')}</Title>
      <List>
        {items.map((cashback) => (
          <li key={cashback.id}>
            <CashbackItemButton
              aria-label={`${t('cashback-item-see-details-button-label')} ${
                cashback?.store?.name
              }`}
              onClick={() => showDetailsModal(cashback)}
            >
              <CashbackItem
                key={cashback.registeredAt}
                store={cashback?.store?.name}
                date={cashback.registeredAt}
                value={cashback.cashbackValue}
                status={cashback.status}
                icon={CashBackIcon[cashback.status]}
              />
            </CashbackItemButton>
          </li>
        ))}
      </List>
    </Container>
  );
};
