import { getProbablyLoggedCookie } from '@/infra/auth/tokenManagement';
import {
  isCouponSEOForAllStoresEnabled,
  isCouponSEOPageEnabled,
} from '@/presentation/services/featureFlags';
import { isSEOCouponSlug } from '@/presentation/services/seo';
import { getCashbackBalanceUrl } from '@/presentation/services/urls/cashback';
import { MittEmitter } from 'next/dist/shared/lib/mitt';
import { useEffect, useState } from 'react';

export function useRouteChange(
  events: MittEmitter<
    | 'routeChangeStart'
    | 'routeChangeComplete'
    | 'routeChangeError'
    | 'beforeHistoryChange'
    | 'hashChangeStart'
    | 'hashChangeComplete'
  >
) {
  const [loadingDeal, setLoadingDeal] = useState(false);
  const [loadingCoupons, setLoadingCoupons] = useState(false);
  const [loadingCashbackBalance, setLoadingCashbackBalance] = useState(false);

  useEffect(() => {
    const start = (url: string) => {
      const isCouponPage = !!url.match(/cupons-de-descontos\/[a-z]/);
      const isDealCouponPage = !!url.match(/cupons-de-descontos\/\S+\/\S+/);
      const isCashbackBalancePage = url.includes(getCashbackBalanceUrl());
      const isTab = url.includes('tab=');
      const previousUrl = window.location.pathname;
      const urlWithoutQuery = url.split('?')[0];
      const isSamePage = previousUrl === urlWithoutQuery;

      const slug = url.split('/')[2];

      const isCouponSEOFeatureEnabled = isCouponSEOPageEnabled();
      const isCouponSEOFeatureEnabledForAllStores =
        isCouponSEOForAllStoresEnabled();

      const probablyLogged = getProbablyLoggedCookie();

      const isCouponSEOPage =
        isSEOCouponSlug(slug) && isCouponSEOFeatureEnabled && !probablyLogged;

      const isCouponSEOPageForAllStores =
        isCouponSEOFeatureEnabledForAllStores && !probablyLogged;

      // Info: Este é um workaround para evitar que a página entre em um loop de carregamento infinito
      // caso o usuário tente voltar para ela após acessar a nova página de cupons.
      const isCouponEligibleForSEO =
        isCouponSEOPage || isCouponSEOPageForAllStores;

      const shouldShowCouponPageLoading =
        isCouponPage && !isTab && !isSamePage && !isCouponEligibleForSEO;

      if ((url.includes('/d/') || isDealCouponPage) && !isTab) {
        window.scrollTo({ top: 0 });
        setLoadingDeal(true);
      }

      if (shouldShowCouponPageLoading) {
        window.scrollTo({ top: 0 });
        setLoadingCoupons(true);
      }

      if (isCashbackBalancePage) {
        setLoadingCashbackBalance(true);
      }
    };
    const end = () => {
      setLoadingDeal(false);
      setLoadingCoupons(false);
      setLoadingCashbackBalance(false);
    };
    events.on('routeChangeStart', start);
    events.on('routeChangeComplete', end);
    events.on('routeChangeError', end);
    return () => {
      events.off('routeChangeStart', start);
      events.off('routeChangeComplete', end);
      events.off('routeChangeError', end);
    };
  }, [events]);

  return { loadingDeal, loadingCoupons, loadingCashbackBalance };
}
