import {
  BodyLight,
  Button,
  Colors,
  H2Heavy,
  Icon,
  MediaQuery,
  SmallLight,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';

export const IconTitle = styled(Icon)`
  color: rgb(${Theme.colors.Brand});
  font-size: 48px;
  margin-bottom: 24px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 16px;
  }
`;

export const Title = styled.h3`
  ${H2Heavy}
  margin-bottom: 8px;
`;

export const Subtitle = styled.p`
  ${BodyLight}
  color: rgb(${Colors.Gray});
  margin-bottom: 24px;
  text-align: center;
`;

export const ModalButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: 16px;
  height: 48px;
`;

export const Container = styled.div`
  text-align: center;
`;

export const Tip = styled.div`
  ${SmallLight}
  display: flex;
  gap: 8px;
  padding: 12px 8px;
  border-radius: 8px;
  background: rgb(${Theme.colors.Kilo});
  margin-bottom: 24px;
  align-items: start;
  text-align: justify;

  i {
    font-size: 24px;
  }
`;
