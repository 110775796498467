import { MediaQuery } from '@pelando/components';
import { css } from 'styled-components';

export const mediumScreenModalStyle = css`
  @media ${MediaQuery.SCREEN_MD_UP} {
    max-width: 391px;
  }
`;

export const mediumScreenCachbackModalStyle = css`
  @media ${MediaQuery.SCREEN_MD_UP} {
    max-width: 500px;
  }
`;
