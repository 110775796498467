import { useCallback, useEffect, useState } from 'react';
import { BannerType, useModal, useToast } from '@pelando/components';
import { useRouter } from 'next/router';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { HEADER_HEIGHT } from '@/presentation/components/Header/styles';
import { ConfirmEmailModal } from '@/presentation/components/ConfirmEmailModal';
import { mediumScreenModalStyle } from '@/presentation/styles/shared/modal';
import { sendEmailConfirmation } from '@/infra/api/auth/endpoints';
import { useCurrentUserCache } from '@/presentation/hooks/useCurrentUserCache';
import { LockScrollGlobally } from '@/presentation/styles/global';
import { CONTACT } from '@/presentation/services/urls/consts';
import { Langs } from '@/locales';

type UseEmailConfirmationGuardModalProps = {
  isCashbackPage?: boolean;
};

export function useEmailConfirmationGuardModal({
  isCashbackPage,
}: UseEmailConfirmationGuardModalProps = {}) {
  const { t } = useTranslation('common');
  const { loggedUser } = useCurrentUserCache();

  const { showModal, closeModal } = useModal();
  const { showToast } = useToast();
  const { push, locale } = useRouter();
  const [sent, setHasSent] = useState(false);

  const { emailConfirmed } = loggedUser || {};

  // TODO: Refatorar função para criar as funções de erro e sucesso fora do escopo da função
  const showConfirmEmail = useCallback(() => {
    const onError = () => {
      showToast({
        bannerType: BannerType.ERROR,
        titleText: t('email-confirmation-title-error'),
        topPosition: HEADER_HEIGHT,
        text: t('email-confirmation-text-error'),
      });
    };

    const handleBackToHome = () => {
      push('/');
      closeModal();
    };

    const handleGoToChat = () => {
      push(CONTACT[locale as Langs]);
      closeModal();
    };

    const handleSendEmailConfirmation = () =>
      sendEmailConfirmation
        .requestAsPromise()
        .then(({ data }) => {
          if (!data) onError();

          showToast({
            bannerType: BannerType.SUCCESS,
            titleText: t('use-enhanced-identify-confirm-email-routes-title'),
            topPosition: HEADER_HEIGHT,
            text: t('use-enhanced-identify-confirm-email-routes-text'),
          });
          closeModal();
          setHasSent(true);
        })
        .catch(() => onError());

    showModal(
      <>
        <ConfirmEmailModal
          onConfirm={handleSendEmailConfirmation}
          onBackToHome={isCashbackPage ? handleGoToChat : handleBackToHome}
          emailHasSent={sent}
          isCashbackPage={isCashbackPage}
        />
        <LockScrollGlobally lock />
      </>,
      {
        preventClose: !isCashbackPage,
        style: mediumScreenModalStyle,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sent]);

  useEffect(() => {
    if (emailConfirmed === false && !isCashbackPage) {
      // TODO: Investigar porque os modais não estão sendo exibidos inicialmente com o useEffect
      setTimeout(showConfirmEmail, 1);
    }
  }, [emailConfirmed, showConfirmEmail, isCashbackPage]);

  return { showConfirmEmail };
}
