import { Icon, useModal } from '@pelando/components';
import { CircleQuestion } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { CashbackTip } from '@/domain/entities/Cashback';
import {
  BalanceSectionHeader,
  ContentBalance,
  HelpCenterLink,
  Subtitle,
  Title,
} from './style';
import { CashbackTipsModal } from '../CashbackTipsModal';

type BalanceSectionProps = {
  className?: string;
  tips: CashbackTip[];
  balanceAvailable?: number;
  withdrawalAllowed?: boolean;
};

export const BalanceSection = ({
  className,
  tips,
  balanceAvailable,
  withdrawalAllowed,
}: BalanceSectionProps) => {
  const { t } = useTranslation('cashbackBalance');
  const { showModal } = useModal();

  const handleClickHelCenter = () => {
    showModal(<CashbackTipsModal tips={tips} />);
  };

  return (
    <section className={className}>
      <BalanceSectionHeader>
        <div>
          <Title>{t('cashback-balance-title')}</Title>
          <Subtitle>{t('cashback-balance-subtitle')}</Subtitle>
        </div>
        <HelpCenterLink
          aria-label={t('help-center-link-label')}
          onClick={handleClickHelCenter}
        >
          <Icon icon={CircleQuestion} />
        </HelpCenterLink>
      </BalanceSectionHeader>
      <ContentBalance
        balanceAvailable={balanceAvailable}
        withdrawalAllowed={withdrawalAllowed}
      />
    </section>
  );
};
