import { css, styled } from 'styled-components';
import {
  CaptionHeavy,
  Colors,
  Icon,
  Skeleton,
  SmallLight,
  TextSkeleton,
  Theme,
} from '@pelando/components';
import { CashbackStatus } from '@/domain/entities/Cashback';

export const Container = styled.div`
  display: flex;
  padding: 16px 0;
  justify-content: space-between;
  align-items: center;
`;

export const Info = styled.div`
  ${SmallLight}
  display: flex;
  gap: 8px;
  align-items: flex-start;
  color: rgb(${Colors.Gray});

  i {
    font-size: 24px;
    color: rgb(${Colors.Mustard});
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const IconSkeleton = styled(Skeleton)`
  &&& {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
`;

export const InfoSkeleton = styled(TextSkeleton)`
  &&& {
    height: 12px;
    min-height: 12px;
    width: 116px;
  }
`;

export const InfoSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const Store = styled.strong`
  ${CaptionHeavy}
  color: rgb(${Theme.colors.Alpha});
`;

export const StoreSkeleton = styled(TextSkeleton)`
  &&& {
    height: 18px;
    width: 56px;
    min-width: 56px;
    min-height: 18px;
  }
`;

export const Value = styled.p`
  ${CaptionHeavy}
`;

export const ValueSkeleton = styled(TextSkeleton)`
  &&& {
    height: 18px;
    width: 62px;
    min-width: 62px;
    min-height: 18px;
  }
`;

export const CustomIcon = styled(Icon)<{ status: CashbackStatus }>`
  font-size: 24px;

  ${({ status }) => {
    if (status === CashbackStatus.PENDING)
      return css`
        color: rgb(${Colors.Mustard});
      `;

    if (status === CashbackStatus.SUCCESS)
      return css`
        color: rgb(${Colors.Green});
      `;

    if (status === CashbackStatus.CANCELED)
      return css`
        color: rgb(${Colors.Gray});
      `;
    return css`
      color: rgb(${Colors.Green});
    `;
  }};
`;
