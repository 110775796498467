import { LockScrollGlobally } from '@/presentation/styles/global';
import { Icon, useModal } from '@pelando/components';
import { useCallback } from 'react';
import { CircleInfo } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { mediumScreenCachbackModalStyle } from '@/presentation/styles/shared/modal';
import Translation from '@/presentation/components/Translation';
import {
  ConfirmButton,
  Container,
  Price,
  Tip,
  Title,
  Subtitle,
  PriceContent,
  Divisor,
  DataContent,
  LabelContent,
  Label,
  Data,
} from './style';

const CashbackRedeem = () => {
  const { t } = useTranslation('cashbackBalance');

  return (
    <Container>
      <PriceContent>
        <Title>{t('cashback-redeem-modal-title')}</Title>

        <Price>R$ 35,50</Price>
        <Subtitle>{t('cashback-redeem-modal-subtitle')}</Subtitle>
      </PriceContent>
      <Divisor />
      <DataContent>
        <Title style={{ marginBottom: 8 }}>
          {t('cashback-redeem-modal-data-title')}
        </Title>
        <LabelContent>
          <Label>{t('cashback-redeem-modal-label-name')}</Label>
          <Data>Pelando da silva</Data>
        </LabelContent>
        <LabelContent>
          <Label>{t('cashback-redeem-modal-label-cpf')}</Label>
          <Data>135.534.395-39</Data>
        </LabelContent>
        <LabelContent>
          <Label>{t('cashback-redeem-modal-label-pix')}</Label>
          <Data>minhachavepix28dbcsd8vcxl</Data>
        </LabelContent>
      </DataContent>
      <Tip>
        <Icon icon={CircleInfo} />
        <span>
          <Translation translation={t('cashback-redeem-modal-tip')} hasTag>
            O envio do cashback pode demorar até <strong>10 dias úteis</strong>
            para chegar na sua conta.
          </Translation>
        </span>
      </Tip>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <ConfirmButton onClick={() => {}}>
          {t('cashback-redeem-modal-button')}
        </ConfirmButton>
      </div>
    </Container>
  );
};

export const useCashbackRedeemModal = () => {
  const { showModal, closeModal: closeRedeemModal } = useModal();

  const showRedeemModal = useCallback(() => {
    showModal(
      <>
        <CashbackRedeem />
        <LockScrollGlobally lock />
      </>,
      {
        style: mediumScreenCachbackModalStyle,
      }
    );
  }, [showModal]);

  return {
    showRedeemModal,
    closeRedeemModal,
  };
};

export default CashbackRedeem;
