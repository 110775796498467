import { useCallback } from 'react';
import { useModal } from '@pelando/components';
import {
  CashbackDescriptionMap,
  CashbackStatus,
  CashbackStatusMap,
  type CashbackUserInfo,
} from '@/domain/entities/Cashback';
import { LockScrollGlobally } from '@/presentation/styles/global';
import { getCurrencyStringByValue } from '@/presentation/services/strings';
import {
  getContactPelandoUrl,
  getPelandoHelpCenterUrl,
} from '@/presentation/services/urls';
import { useTranslation } from '@/presentation/hooks/useTranslation';

import { Ban, CircleCheck, Clock } from '@pelando/fontawesome/icons';

import { useRouter } from 'next/router';
import {
  modalStyle,
  Container,
  StoreImage,
  Header,
  StoreName,
  Value,
  PurchaseInfo,
  History,
  GoBackButton,
  Footer,
  InfoLink,
  PendingIcon,
  SuccessPurchaseIcon,
  HistoryItem,
  ContactButton,
} from './style';

type CashbackDetailsModalProps = {
  cashbackInfo: CashbackUserInfo;
  onGoBack: () => void;
};

export const CashbackDetailsModal = ({
  cashbackInfo,
  onGoBack,
}: CashbackDetailsModalProps) => {
  const { push, locale } = useRouter();
  const { t } = useTranslation('cashbackBalance');
  const {
    store,
    cashbackValue,
    cashbackPercentage,
    totalValue,
    registeredAt,
    previousStatus,
  } = cashbackInfo;

  const storeImage = store?.image?.url;
  const storeName = store?.name;
  const formattedRegisteredAt = new Date(registeredAt).toLocaleDateString();
  const formattedTotalValue = getCurrencyStringByValue(totalValue);
  const formattedCashbackValue = getCurrencyStringByValue(cashbackValue);

  const getStatus = (storeName: string, status: CashbackStatus) => {
    if (status !== CashbackStatus.PENDING)
      return `${t('cashback-item-prefix')} ${storeName} ${t(
        CashbackDescriptionMap[status]
      )}`;

    return t(CashbackDescriptionMap[status]);
  };

  const isCashbackCanceled = previousStatus?.some(
    (status) => status?.status === CashbackStatus.CANCELED
  );

  const CashBackIcon = {
    [CashbackStatus.PENDING]: Clock,
    [CashbackStatus.SUCCESS]: CircleCheck,
    [CashbackStatus.CANCELED]: Ban,
  };

  return (
    <Container>
      <Header>
        <StoreImage src={storeImage} alt={storeName} />
        <StoreName>{storeName}</StoreName>
        <Value>{formattedCashbackValue}</Value>
        <PurchaseInfo>
          {cashbackPercentage}% {t('cashback-details-percentage-preposition')}{' '}
          {formattedTotalValue}
        </PurchaseInfo>
      </Header>
      <History>
        {previousStatus?.map((status, index) => {
          const { status: currentStatus, registeredAt } = status;

          const pendingApproved =
            currentStatus === CashbackStatus.PENDING &&
            previousStatus.length - 1 !== index;

          const pendingStatus = pendingApproved
            ? CashbackStatus.SUCCESS
            : CashbackStatus.PENDING;

          const iconStatus =
            currentStatus === CashbackStatus.PENDING
              ? pendingStatus
              : currentStatus;

          return (
            <HistoryItem key={registeredAt}>
              <PendingIcon
                status={iconStatus}
                icon={CashBackIcon[iconStatus]}
              />
              <strong>{t(CashbackStatusMap[currentStatus])}</strong>
              <span>{getStatus(storeName, currentStatus)}</span>
              <span>{new Date(registeredAt).toLocaleDateString()}</span>
            </HistoryItem>
          );
        })}
        <HistoryItem>
          <SuccessPurchaseIcon icon={CircleCheck} />
          <strong>{t('cashback-item-purchase-completed-text')}</strong>
          <span>{formattedRegisteredAt}</span>
        </HistoryItem>
      </History>
      <Footer>
        <GoBackButton onClick={onGoBack}>
          {t('cashback-details-modal-go-back-button-label')}
        </GoBackButton>
        {isCashbackCanceled ? (
          <ContactButton
            light
            onClick={() => push(getContactPelandoUrl(locale))}
          >
            {t('cashback-details-modal-contact-button-label')}
          </ContactButton>
        ) : (
          <InfoLink
            href={getPelandoHelpCenterUrl()}
            rel="noreferrer"
            target="_blank"
          >
            {t('cashback-details-modal-help-center-link-label')}
          </InfoLink>
        )}
      </Footer>
    </Container>
  );
};

export const useCashbackDetailsModal = () => {
  const { showModal, closeModal: closeDetailsModal } = useModal();

  const showDetailsModal = useCallback(
    (cashbackInfo: CashbackUserInfo) => {
      showModal(
        <>
          <CashbackDetailsModal
            cashbackInfo={cashbackInfo}
            onGoBack={closeDetailsModal}
          />
          <LockScrollGlobally lock />
        </>,
        {
          style: modalStyle,
        }
      );
    },
    [closeDetailsModal, showModal]
  );

  return {
    showDetailsModal,
    closeDetailsModal,
  };
};
