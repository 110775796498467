export enum UnleashFlags {
  AUTOMATICALLY_FOLLOW_OFFER = 'AUTOMATICALLY_FOLLOW_OFFER',

  // Ads
  DISABLE_AD_PLACEMENT = 'DISABLE_AD_PLACEMENT',

  // Recommendations
  FEED_ACTIVE_RECOMBEE = 'FEED_ACTIVE_RECOMBEE',
  FEED_ACTIVE_RECOMBEE_LOGGEDOUT = 'FEED_ACTIVE_RECOMBEE_LOGGEDOUT',

  // Pelando Check / Products
  ENABLE_PRODUCT_INDEXING = 'ENABLE_PRODUCT_INDEXING',
  WEB_PRODUCT_FILTER_IMAGE_URL_WORKAROUND = 'WEB_PRODUCT_FILTER_IMAGE_URL_WORKAROUND',
  WEB_ENABLE_COMPARATOR_HOME = 'WEB_ENABLE_COMPARATOR_HOME',
  WEB_PRODUCT_DEALS_LIMIT = 'WEB_PRODUCT_DEALS_LIMIT',
  WEB_COMMENTS_LIMIT_ON_PRODUCT_PAGE = 'WEB_COMMENTS_LIMIT_ON_PRODUCT_PAGE',
  WEB_ENABLE_COMMENT_REPLY_ON_PRODUCT_PAGE = 'WEB_ENABLE_COMMENT_REPLY_ON_PRODUCT_PAGE',
  WEB_ENABLE_PRODUCT_FEATURES = 'WEB_ENABLE_PRODUCT_FEATURES',

  // Deals comment trigger reactions
  ENABLE_COMMENT_TRIGGER_VOTE_REACTION = 'ENABLE_COMMENT_TRIGGER_VOTE_REACTION',

  // Search
  WEB_SEARCH_FACETS_DYNAMIC_UPDATE = 'WEB_SEARCH_FACETS_DYNAMIC_UPDATE',
  WEB_SEARCH_RESULTS_PRODUCT_BUTTON_CTA = 'WEB_SEARCH_RESULTS_PRODUCT_BUTTON_CTA',
  WEB_ENABLE_SEARCH_TERMS_SUGGESTIONS = 'WEB_ENABLE_SEARCH_TERMS_SUGGESTIONS',
  WEB_ENABLE_NEW_FILTERS_N2 = 'WEB_ENABLE_NEW_FILTERS_N2',
  WEB_N1_DEALS_LIMIT = 'WEB_N1_DEALS_LIMIT',
  WEB_ENABLE_SEASONAL_FEED = 'WEB_ENABLE_SEASONAL_FEED',

  // Feed
  WEB_CARD_FIX_TOP = 'WEB_CARD_FIX_TOP',
  WEB_ENABLE_NEW_FEED_PAGINATION = 'WEB_ENABLE_NEW_FEED_PAGINATION',

  WEB_FEED_TAB_RECOMMENDED = 'WEB_FEED_TAB_RECOMMENDED',

  // Comments
  WEB_COMMENT_SUGGESTIONS = 'WEB_COMMENT_SUGGESTIONS',
  WEB_CLICKOUT_RETURN = 'WEB_CLICKOUT_RETURN',
  WEB_INCENTIVE_FIRST_COMMENT = 'WEB_INCENTIVE_FIRST_COMMENT',

  // App Installation
  WEB_INCENTIVE_STANDARD_TOAST = 'WEB_INCENTIVE_STANDARD_TOAST',
  WEB_INCENTIVE_BOTTOM_SHEET_DEALS = 'WEB_INCENTIVE_BOTTOM_SHEET_DEALS',
  WEB_BANNER_INCENTIVE_APP_HOME_DEALS = 'WEB_BANNER_INCENTIVE_APP_HOME_DEALS',

  // Onboarding
  ONBOARDING_USER_TYPE_ACTIVE = 'ONBOARDING_USER_TYPE_ACTIVE',
  WEB_ONBOARDING_CONTEXT_ALERT_REGISTER = 'WEB_ONBOARDING_CONTEXT_ALERT_REGISTER',
  WEB_ONBOARDING_CONTEXT_ALERT_OTHER_FUNCTIONS = 'WEB_ONBOARDING_CONTEXT_ALERT_OTHER_FUNCTIONS',
  WEB_ONBOARDING_INCENTIVE_APP = 'WEB_ONBOARDING_INCENTIVE_APP',
  WEB_ONBOARD_DEALS = 'WEB_ONBOARD_DEALS',
  WEB_WELCOME_STAGE_ONBOARD_DEALS = 'WEB_WELCOME_STAGE_ONBOARD_DEALS',

  // Incentive Login
  WEB_INCENTIVE_LOGIN_BANNER_DEALS = 'WEB_INCENTIVE_LOGIN_BANNER_DEALS',
  WEB_INCENTIVE_LOGIN_BANNER_HOME = 'WEB_INCENTIVE_LOGIN_BANNER_HOME',
  WEB_INCENTIVE_LOGIN_BANNER_SIDE = 'WEB_INCENTIVE_LOGIN_BANNER_SIDE',

  // Cookies
  WEB_COOKIES_ACTIVE = 'WEB_COOKIES_ACTIVE',

  // Black Friday
  WEB_HEADER_BLACK = 'WEB_HEADER_BLACK',
  WEB_DEALS_RECOMMENDATION = 'WEB_DEALS_RECOMMENDATION',
  WEB_ENABLE_BF_LANDING_PAGE = 'WEB_ENABLE_BF_LANDING_PAGE',
  WEB_ENABLE_BF_INTERNAL_LINKS = 'WEB_ENABLE_BF_INTERNAL_LINKS',

  // AB Tests
  AB_TEST_CONTROL = 'AB_TEST_CONTROL',
  WEB_ENABLE_NEW_PRODUCT_CARD_ON_SEARCH_AB_TEST = 'WEB_ENABLE_NEW_PRODUCT_CARD_ON_SEARCH_AB_TEST',
  WEB_SEARCH_LABELS_AB_TEST = 'WEB_SEARCH_LABELS_AB_TEST',

  // Communities
  WEB_COMMUNITY = 'WEB_COMMUNITY',

  // New deal
  DEALS_COMPARATOR_PRICE = 'DEALS_COMPARATOR_PRICE',

  // Translation
  WEB_USERBACK_BUTTON_DISABLED = 'WEB_USERBACK_BUTTON_DISABLED',

  // Blog
  WEB_ENABLE_BLOG_PAGE = 'WEB_ENABLE_BLOG_PAGE',

  // AlertModal
  WEB_ENABLE_INCENTIVE_ALERT_CREATE = 'WEB_ENABLE_INCENTIVE_ALERT_CREATE',

  // Deal feed
  WEB_DEALS_FEED_INFINITE = 'WEB_DEALS_FEED_INFINITE',

  // Post Deal V2
  WEB_ENABLED_POST_DEAL_V2 = 'WEB_ENABLED_POST_DEAL_V2',

  // Deal tabs
  WEB_TAB_DEALS = 'WEB_TAB_DEALS',

  // Header
  WEB_DISABLE_COMMUNITIES = 'WEB_DISABLE_COMMUNITIES',

  // Dopelando
  WEB_DOPELANDO_TIME = 'WEB_DOPELANDO_TIME',
  // New alerts page
  WEB_NEW_ALERT_PAGE = 'WEB_NEW_ALERT_PAGE',

  WEB_FIREBASE_PUSH_ENABLED = 'WEB_FIREBASE_PUSH_ENABLED',

  // Cashback
  WEB_ENABLE_BLACK_FRIDAY_CAMPAIGN = 'WEB_ENABLE_BLACK_FRIDAY_CAMPAIGN',
  WEB_ENABLE_CASHBACK_CLAIM = 'WEB_ENABLE_CASHBACK_CLAIM',

  // Coupon
  WEB_ENABLE_COUPONS_SEO_PAGE = 'WEB_ENABLE_COUPONS_SEO_PAGE',
  WEB_ENABLE_COUPONS_SEO_FOR_ALL_STORES = 'WEB_ENABLE_COUPONS_SEO_FOR_ALL_STORES',

  WEB_DISABLE_ONBOARDING_VIDEO_STEP = 'WEB_DISABLE_ONBOARDING_VIDEO_STEP',

  // Link workaround for new stores focused on SEO
  WEB_ENABLE_SEO_LINK_WORKAROUND = 'WEB_ENABLE_SEO_LINK_WORKAROUND',
}

export type FlagsTypes = Record<UnleashFlags, string | boolean> & {
  AUTOMATICALLY_FOLLOW_OFFER: boolean;
  DISABLE_AD_PLACEMENT: boolean;
  FEED_ACTIVE_RECOMBEE: boolean;
  FEED_ACTIVE_RECOMBEE_LOGGEDOUT: boolean;
  ENABLE_PRODUCT_INDEXING: boolean;
  WEB_PRODUCT_FILTER_IMAGE_URL_WORKAROUND: boolean;
  ENABLE_COMMENT_TRIGGER_VOTE_REACTION: boolean;
  WEB_SEARCH_FACETS_DYNAMIC_UPDATE: boolean;
  WEB_CARD_FIX_TOP: boolean;
  WEB_COMMENT_SUGGESTIONS: boolean;
  WEB_CLICKOUT_RETURN: boolean;
  WEB_INCENTIVE_STANDARD_TOAST: boolean;
  WEB_INCENTIVE_BOTTOM_SHEET_DEALS: boolean;
  WEB_BANNER_INCENTIVE_APP_HOME_DEALS: boolean;
  ONBOARDING_USER_TYPE_ACTIVE: boolean;
  WEB_ONBOARD_DEALS: boolean;
  WEB_WELCOME_STAGE_ONBOARD_DEALS: boolean;
  WEB_COOKIES_ACTIVE: boolean;
  WEB_HEADER_BLACK: boolean;
  WEB_DEALS_RECOMMENDATION: boolean;
  WEB_ONBOARDING_CONTEXT_ALERT_OTHER_FUNCTIONS: boolean;
  WEB_ONBOARDING_INCENTIVE_APP: boolean;
  AB_TEST_CONTROL: string;
  WEB_ENABLE_PRODUCTS_ON_DEAL_ACTIVE: boolean;
  WEB_COMMUNITY: boolean;
  WEB_ENABLE_SEARCH_TERMS_SUGGESTIONS: boolean;
  WEB_USERBACK_BUTTON_DISABLED: boolean;
  WEB_ENABLE_NEW_FILTERS_N2: boolean;
  WEB_ENABLE_BLOG_PAGE: boolean;
  DEALS_COMPARATOR_PRICE: boolean;
  WEB_DEALS_FEED_INFINITE: boolean;
  WEB_ENABLED_POST_DEAL_V2: boolean;
  WEB_ENABLE_BF_LANDING_PAGE: boolean;
  WEB_ENABLE_NEW_FEED_PAGINATION: boolean;
  WEB_ENABLE_NEW_PRODUCT_CARD_ON_SEARCH_AB_TEST: 'current' | 'new';
  WEB_TAB_DEALS: boolean;
  WEB_ENABLE_BF_INTERNAL_LINKS: boolean;
  WEB_SEARCH_LABELS_AB_TEST: 'current' | 'new';
  WEB_PRODUCT_DEALS_LIMIT: string;
  WEB_COMMENTS_LIMIT_ON_PRODUCT_PAGE: string;
  WEB_INCENTIVE_FIRST_COMMENT: boolean;
  WEB_FEED_TAB_RECOMMENDED: boolean;
  WEB_DISABLE_COMMUNITIES: boolean;
  WEB_DOPELANDO_TIME: string;
  WEB_NEW_ALERT_PAGE: boolean;
  WEB_ENABLE_INCENTIVE_ALERT_CREATE: boolean;
  WEB_ENABLE_COMMENT_REPLY_ON_PRODUCT_PAGE: boolean;
  WEB_FIREBASE_PUSH_ENABLED: boolean;
  WEB_N1_DEALS_LIMIT: string;
  WEB_ENABLE_PRODUCT_FEATURES: boolean;
  WEB_ENABLE_SEASONAL_FEED: boolean;
  WEB_ENABLE_BLACK_FRIDAY_CAMPAIGN: boolean;
  WEB_ENABLE_COUPONS_SEO_PAGE: boolean;
  WEB_DISABLE_ONBOARDING_VIDEO_STEP: boolean;
  WEB_ENABLE_CASHBACK_CLAIM: boolean;
  WEB_ENABLE_SEO_LINK_WORKAROUND: boolean;
  WEB_ENABLE_COUPONS_SEO_FOR_ALL_STORES: boolean;
};
