export const GET_CASHBACK_AVAILABLE_BALANCE = `
    query GetCashbackAvailableBalance {
        me {
            cashbackAvailableBalance {
                balance
                withdrawalAllowed
            }
        }
    }
`;
