import { getCurrencyStringByValue } from '@/presentation/services/strings';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { CashbackStatus, CashbackStatusMap } from '@/domain/entities/Cashback';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { Container, CustomIcon, Info, Store, Value } from './style';

export type CashbackItemProps = {
  store: string;
  date: string;
  value: number;
  status: CashbackStatus;
  icon: IconDefinition;
};

export const CashbackItem = ({
  date,
  store,
  value,
  status,
  icon,
}: CashbackItemProps) => {
  const { t } = useTranslation('cashbackBalance');
  const formattedDate = new Date(date).toLocaleDateString();
  const formattedValue = getCurrencyStringByValue(value);

  const statusText = t(CashbackStatusMap[status]);

  return (
    <Container>
      <Info>
        <CustomIcon icon={icon} status={status} />
        <div>
          <Store>{store}</Store>
          <p>{statusText}</p>
          <p>{formattedDate}</p>
        </div>
      </Info>
      <Value>{formattedValue}</Value>
    </Container>
  );
};
